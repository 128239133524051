@import 'bootstrap/scss/functions';

.text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
  --#{$prefix}danger-rgb: #{to-rgb(danger-text-color-dark-background('bg-lvl1'))};
}

$adapted_text_colors: (
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'white',
  'light'
);
@each $color in $adapted_text_colors {
  .bg-#{$color} {
    color: $primary-alt;
  }
  @each $opacity in map-keys($opacities) {
    .bg-#{$color} {
      &.bg-opacity-#{$opacity} {
        color: $primary;
      }
    }
  }
}
