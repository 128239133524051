.btn.btn-flat-danger {
  $dark-danger: danger-text-color-dark-background('bg-lvl1');

  color: $dark-danger;

  &:hover {
    color: $dark-danger;
  }

  &:focus,
  &.focus {
    color: $dark-danger;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-danger {
  $dark-danger: discreet-danger-text-color-dark-background('bg-lvl1');

  color: $dark-danger;

  &:hover {
    color: $dark-danger;
  }

  &:focus,
  &.focus {
    color: $dark-danger;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-info {
  $dark-info: info-discreet-color-on-dark('bg-lvl1');

  color: $dark-info;

  &:hover {
    color: $dark-info;
  }

  &:focus,
  &.focus {
    color: $dark-info;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-secondary {
  $dark-secondary: dark-discreet-color('bg-lvl1', 'secondary');

  color: $dark-secondary;

  &:hover {
    color: $dark-secondary;
  }

  &:focus,
  &.focus {
    color: $dark-secondary;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}