.badge-discreet-secondary {
  --#{$prefix}badge-color: #{dark-discreet-color('bg-lvl1', 'secondary')} !important;
}

.bagde-discreet-info {
  --#{$prefix}badge-color: #{info-discreet-color-on-dark('bg-lvl1')} !important;
}

.badge-discreet-danger {
  --#{$prefix}badge-color: #{discreet-danger-text-color-dark-background('bg-lvl1')} !important;
}
